<template>

    <div v-if="!mobile" class="box  overflow-hidden  flex flex-col relative" :class="billing ? 'h-1/2' : 'w-3/5 ml-1'">

        <loader :loading="loading" />

        <div class="flex items-center mb-2">
            <div class="box-title">Rate</div>
            <!-- <div class="text-plan ml-auto">PMC: {{revenueDaysData.pmc}}d</div> -->
        </div>

        <div v-if="rateData" class="flex justify-between items-center mx-2">
            <div>
                <div class="text-font-gray text-sm mt-1">Average Rate</div>
                <div class="text-xl font-bold">{{rateData.average_rate | numberFormat(2)}}€/h</div>
            </div>
            <div>
                <div class="text-font-gray text-sm mt-1">vs Future </div>
                <div class="text-xl font-bold" :class="{'text-good': rateData.vs_average_rate > 0, 'text-danger': rateData.vs_average_rate <= 0}">{{rateData.vs_average_rate | numberFormat(2)}} ptos.</div>
            </div>
            <div>
                <div class="text-font-gray text-sm mt-1">Future Average Rate</div>
                <div class="text-xl font-bold">{{rateData.future_average_rate | numberFormat(2)}}€/h</div>
            </div>
        </div>

    </div>

    <div v-else class="h-auto rounded-lg flex flex-col overflow-hidden bg-filters relative p-2">

        <loader :loading="loading" />

        <div class="flex items-center mb-2">
            <div class="text-font-gray font-semibold text-xs">Revenue Days</div>
            <div class="text-font-gray text-plan text-xs ml-auto">PMC: {{revenueDaysData.pmc}}d</div>
        </div>

        <div class="h-full overflow-hidden  flex flex-col justify-around">

            <div class="flex items-center mt-1" v-for="(period, index) in revenueDaysData.revenue_days"
                :key="period.literal">
                <div class="flex-none w-12 text-xxs text-font-dark text-right pr-2">
                    {{ period.literal }}
                </div>
                <div class="w-full h-3">
                    <!-- <div class="rounded  text-xs shadow-lg  h-full"
                        :class="period.percentage < 50 ? 'bg-gradient-progress_lilac' : 'bg-gradient-progress_orange'"
                        :style="'width:' + period.percentage  + '%;'">
                    </div> -->
                    <div class="rounded  text-xs shadow-lg  h-full"
                        :class="{'bg-gradient-progress_lilac': index < 3 || (index == 2 && period.percentage < 50), 'bg-gradient-progress_orange': index > 2 || (index == 2 && period.percentage > 50)}"
                        :style="'width:' + period.percentage  + '%;'">
                    </div>
                </div>
                <div class="flex-none w-12 text-xs text-right pl-2 font-semibold">
                    {{ period.pmc  | reduceBigNumbers() }}
                </div>
            </div>

        </div>

    </div>

</template>

<script>

import { state } from '@/store';


export default {
    props: ['rate','mobile', 'billing'],
    data() {
        return {
            loading: false,
            revenueDaysData: false,
            max_value:0,
            rateData: false
        }
    },
    methods: {
        preload() {
            this.loading = true
        },
        load() {

            // if(state.rolSelected.role_type_link != 'presidencia'){
            
                this.axios.get('billing/revenue_days', {params: this.params}).then(response => {
                    this.revenueDaysData = response.data.data;

                    for (let index = 0; index < this.revenueDaysData.revenue_days.length; index++) {
                        
                        if(this.revenueDaysData.revenue_days[index].percentage > this.max_value){
                            this.max_value = this.revenueDaysData.revenue_days[index].percentage
                        }

                    }
                })


                this.axios.get('projects_management/ner', {params: this.params}).then(response => {
                    this.rateData = response.data.data
                    this.loading = false;
                })

            // }
            
            this.loading = false;
        }
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
    },
    watch: {
        params() { this.load() }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}
</script>